// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {K4_ofzIps: {hover: true}};

const cycleOrder = ["K4_ofzIps"];

const serializationHash = "framer-bCIkY"

const variantClassNames = {K4_ofzIps: "framer-v-5stcsn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tap, width, ...props}) => { return {...props, o4zUTtjdn: tap ?? props.o4zUTtjdn} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, o4zUTtjdn, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "K4_ofzIps", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapn7u76d = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (o4zUTtjdn) {const res = await o4zUTtjdn(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-5stcsn", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"K4_ofzIps"} onTap={onTapn7u76d} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}} variants={{"K4_ofzIps-hover": {backgroundColor: "rgba(255, 255, 255, 0.1)"}}} {...addPropertyOverrides({"K4_ofzIps-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1jgissl"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"w0O_3wydp"}><motion.div className={"framer-zxmed"} data-framer-name={"Vector"} layoutDependency={layoutDependency} layoutId={"iknJKDbLe"} style={{backgroundColor: "var(--token-2c541943-a99a-4ecb-bbe1-39c81e32ad57, rgb(142, 142, 142))", rotate: -45}} variants={{"K4_ofzIps-hover": {backgroundColor: "var(--token-09f22285-1280-482e-8b22-1cef593bf6a7, rgb(255, 255, 255))"}}}/><motion.div className={"framer-1s8mldd"} data-framer-name={"Vector"} layoutDependency={layoutDependency} layoutId={"VlUfLsGUy"} style={{backgroundColor: "var(--token-2c541943-a99a-4ecb-bbe1-39c81e32ad57, rgb(142, 142, 142))", rotate: 45}} variants={{"K4_ofzIps-hover": {backgroundColor: "var(--token-09f22285-1280-482e-8b22-1cef593bf6a7, rgb(255, 255, 255))"}}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bCIkY.framer-t6tvl2, .framer-bCIkY .framer-t6tvl2 { display: block; }", ".framer-bCIkY.framer-5stcsn { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 6px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-bCIkY .framer-1jgissl { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); overflow: visible; position: relative; width: 20px; }", ".framer-bCIkY .framer-zxmed { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-bCIkY .framer-1s8mldd { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 9px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bCIkY.framer-5stcsn { gap: 0px; } .framer-bCIkY.framer-5stcsn > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-bCIkY.framer-5stcsn > :first-child { margin-top: 0px; } .framer-bCIkY.framer-5stcsn > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"KOYHwi77r":{"layout":["auto","auto"]}}}
 * @framerVariables {"o4zUTtjdn":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLrWkI9TpG: React.ComponentType<Props> = withCSS(Component, css, "framer-bCIkY") as typeof Component;
export default FramerLrWkI9TpG;

FramerLrWkI9TpG.displayName = "Elements/Banner close btn";

FramerLrWkI9TpG.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerLrWkI9TpG, {o4zUTtjdn: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerLrWkI9TpG, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})